import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';

const getFromStorage = () => {
  if (typeof window !== 'undefined') {
    const storage = localStorage.getItem('cookieConsent');
    return !!storage;
  }
  return false;
};

const setToStorage = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('cookieConsent');
  }
};

function Privacy({ location }) {
  const [accepted, setAccepted] = useState(getFromStorage());

  const acceptHandler = () => {
    setToStorage();
    setAccepted(false);
  };

  return (
    <Layout pathname={location.pathname}>
      <SEO title="Datenschutz" />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Datenschutz</h1>
          <div className="row">
            <div className="col col--lg-8">
              {!accepted ? (
                <p>Das Tracking durch Google Analytics ist für diese Website deaktiviert.</p>
              ) : (
                <p>
                  <button aria-label="Tracking durch Google Analytics für diese Website deaktivieren" onClick={acceptHandler} className="button button--ghost-dark">
                    Tracking durch Google Analytics für diese Website deaktivieren.
                  </button>
                </p>
              )}

              <h4>Allgemeine Hinweise</h4>
              <p>
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was
                mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
                besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                persönlich identifiziert werden können. Ausführliche Informationen zum
                Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
                Datenschutzerklärung.
                <br />
                Datenerfassung auf unserer Website
              </p>
              <h4>
                Wer ist verantwortlich für die Datenerfassung auf dieser Website?
              </h4>
              <p>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
                Website entnehmen.
              </p>
              <h4>Wie erfassen wir Ihre Daten?</h4>
              <p>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
                Kontaktformular eingeben.
                <br />
                Andere Daten werden automatisch beim Besuch der Website durch unsere
                IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
                Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
                betreten.
              </p>
              <h4>Wofür nutzen wir Ihre Daten?</h4>
              <p>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
                der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                Nutzerverhaltens verwendet werden.
              </p>
              <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
              <p>
                Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
                Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
                erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
                oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
                Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
                Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
                ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu
              </p>
              <h4>Allgemeine Hinweise und Pflichtinformationen Datenschutz</h4>
              <p>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
                sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                Datenschutzerklärung.
                <br />
                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
                Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                persönlich identifiziert werden können. Die vorliegende
                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
                sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                geschieht.
                <br />
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
                der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                möglich.
              </p>
              <h4>Hinweis zur verantwortlichen Stelle</h4>
              <p>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                Website ist:
                <br />
                <strong>Sauer - Catering & Ladenlokal am Sorpesee</strong>
                <br />
                <strong>Zum Rosengarten 3</strong>
                <br />
                <strong>59846 Sundern</strong>
                <br />
                <strong>Tel 0 29 35 / 25 93</strong>
                <br />
                <strong>info@sauer-catering.de</strong>
              </p>
              <p>
                Verantwortliche Stelle ist die natürliche oder juristische Person, die
                allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
                o. Ä.) entscheidet.
              </p>
              <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
              <p>
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
                Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
                an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unberührt.
              </p>
              <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
              <p>
                Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
                Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
                Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
                Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
                sowie deren Kontaktdaten können folgendem Link entnommen werden:
                <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                </a>
                .
                <br />
                Recht auf Datenübertragbarkeit
              </p>
              <p>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
                oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
                oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
                einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
                technisch machbar ist.
              </p>
              <h4>Auskunft, Sperrung, Löschung</h4>
              <p>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
                das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
                der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
                oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                personenbezogene Daten können Sie sich jederzeit unter der im
                Impressum angegebenen Adresse an uns wenden.
              </p>
              <h4>Widerspruch gegen Werbe-Mails</h4>
              <p>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
                Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
                Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                Spam-E-Mails, vor.
              </p>
              <h4>SSL Verschlüsselung</h4>
              <p>
                Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
                übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
                Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
                verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                Browsers von http:// auf https:// wechselt und an dem
                Schloss-Symbol in Ihrer Browserzeile.
                <br />
                Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie
                an uns übermitteln, nicht von Dritten mitgelesen werden.
              </p>
              <h4>Cookies</h4>
              <p>
                Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
                richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
                Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
                sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
                Rechner abgelegt werden und die Ihr Browser speichert.
              </p>
              <p>
                Die meisten der von uns verwendeten Cookies sind so genannte
                “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
                gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
                Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
                beim nächsten Besuch wiederzuerkennen.
              </p>
              <p>
                <br />
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
                Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
                Annahme von Cookies für bestimmte Fälle oder generell ausschließen
                sowie das automatische Löschen der Cookies beim Schließen des Browser
                aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
                dieser Website eingeschränkt sein.
                <br />
                Cookies, die zur Durchführung des elektronischen
                Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
                erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
                werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
                Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
                Cookies zur technisch fehlerfreien und optimierten Bereitstellung
                seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
                Surfverhaltens) gespeichert werden, werden diese in dieser
                Datenschutzerklärung gesondert behandelt.
              </p>
              <h4>Server-Log-Files</h4>
              <p>
                Der Provider der Seiten erhebt und speichert automatisch Informationen
                in so genannten Server-Log Files, die Ihr Browser automatisch an uns
                übermittelt. Dies sind:
              </p>
              <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                <li>
                  Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                  nicht vorgenommen.
                </li>
                <li>
                  Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO,
                  der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
                  vorvertraglicher Maßnahmen gestattet.
                </li>
              </ul>
              <h4>Kontaktformular</h4>
              <p>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
                Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                nicht ohne Ihre Einwilligung weiter.
                <br />
                Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
                somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
                lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen.
                Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
                Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                <br />
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
                uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                Speicherung widerrufen oder der Zweck für die Datenspeicherung
                entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
                – bleiben unberührt.
              </p>
              <h4>Google Analytics</h4>
              <p>
                Diese Website nutzt Funktionen des Webanalysedienstes Google
                Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
                Mountain View, CA 94043, USA.
                <br />
                Google Analytics verwendet so genannte Cookies. Das sind
                Textdateien, die auf Ihrem Computer gespeichert werden und die eine
                Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
                Cookie erzeugten Informationen über Ihre Benutzung dieser Website
                werden in der Regel an einen Server von Google in den USA übertragen
                und dort gespeichert.
              </p>
              <h4>IP Anonymisierung</h4>
              <p>
                Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
                Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten
                der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
                über den Europäischen Wirtschaftsraum vor der übermittlung in die USA
                gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                Server von Google in den USA übertragen und dort gekürzt. Im Auftrag
                des Betreibers dieser Website wird Google diese Informationen
                benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten zusammenzustellen und um weitere mit der
                Websitenutzung und der Internetnutzung verbundene Dienstleistungen
                gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
                Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                anderen Daten von Google zusammengeführt.
              </p>
              <h4>Browser Plugin</h4>
              <p>
                Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
                darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können. Sie
                können darüber hinaus die Erfassung der durch den Cookie erzeugten und
                auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
                an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
                indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
                herunterladen und installieren:
                <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
              </p>
              <h4>Widerspruch gegen Datenerfassung</h4>
              <p>
                Sie können die Erfassung Ihrer Daten durch Google Analytics
                verhindern, indem Sie auf folgenden Link klicken. Es wird ein
                Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
                Besuchen dieser Website verhindert: Google Analytics deaktivieren
                <br />
                Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
                finden Sie in der Datenschutzerklärung von Google:
                <a href="https://support.google.com/analytics/answer/6004245?hl=de">
                  https://support.google.com/analytics/answer/6004245?hl=de
                </a>
              </p>
              <h4>Auftragsdatenverarbeitung</h4>
              <p>
                Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
                abgeschlossen und setzen die strengen Vorgaben der deutschen
                Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
                um.
              </p>
              <h4>Demografische Merkmale bei Google Analytics</h4>
              <p>
                Diese Website nutzt die Funktion demografische Merkmale von Google
                Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
                Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
                Daten stammen aus interessenbezogener Werbung von Google sowie aus
                Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
                Person zugeordnet werden. Sie können diese Funktion jederzeit über die
                Anzeigeneinstellungen in Ihrem Google- Konto deaktivieren oder die
                Erfassung Ihrer Daten durch Google Analytics wie im Punkt Widerspruch
                gegen Datenerfassung dargestellt generell untersagen.
              </p>
              <p>Quelle des obigen Textes: e-recht24.de</p>
            </div>

            <div className="col col--lg-8 mt--md">
              <h4>Was sind Google Fonts?</h4>
              <p>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
              <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
              <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google Ihren Nutzern kostenlos zu Verfügung stellen.</p>
              <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
              <p>
                Quelle &quot;Was sind Google Fonts&quot;: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple
              </p>
              <p>
                Alle Texte sind urheberrechtlich geschützt.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

Privacy.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Privacy;
